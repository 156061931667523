import Product from "./Product";
import styled from "styled-components";
import { useProductContext } from "../context/ProductContext";

const FeatureProduct = () => {
  const featureProducts = useProductContext();

  if (featureProducts.state.isLoading) {
    return (
      <Wrapper className="section">
        <div className="container">
          <div className="feature-intro">Check Now!</div>
          <div className="featured-heading">Featured Products</div>
          <div className="loading-text">Loading Featured Products ...</div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper className="section">
      <div className="container">
        <div className="feature-intro">Check Out Now!</div>
        <div className="featured-heading">Our Feature Products</div>
        <div className="grid-feature-products grid-four-column">
          {featureProducts.state.featureProducts.map((curElem) => {
            return <Product key={curElem.id} {...curElem} />;
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 9rem 0;
  background-color: ${({ theme }) => theme.colors.bg};
  transition: background-color 0.3s ease-in-out;

  .container {
    max-width: 120rem;
    padding: 0 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    text-align: center; /* Align text to the center */
  }

  .loading-text {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 3rem;
    color: ${({ theme }) => theme.colors.text};
    animation: fadeIn 1s ease-in-out;
  }

  .featured-heading {
    font-size: 4.8rem;
    font-weight: 600;
    margin-bottom: 6rem;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.primary};
    animation: fadeIn 1s ease-in-out;
  }

  .feature-intro {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #5138ee;
    text-align: center;
    font-size: 2rem;
    letter-spacing: 0.2rem;
    animation: fadeIn 1s ease-in-out;
  }

  .grid-feature-products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    animation: fadeInUp 1s ease-in-out;
    width: 100%;
    justify-items: center; /* Center grid items */
  }

  @media (max-width: ${({ theme }) => theme.media.tablet}) {
    .grid-feature-products {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-feature-products {
      grid-template-columns: 1fr;
    }
    .feature-intro {
      font-size: 1.6rem; /* Adjust font size */
    }
    .featured-heading {
      font-size: 2.8rem; /* Adjust font size */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  figure {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: width 0.3s ease;
      cursor: pointer;
    }

    &:hover::after {
      width: 100%;
    }

    &:hover img {
      transform: scale(1.1);
      transition: transform 0.4s ease;
    }

    img {
      max-width: 100%;
      height: 25rem;
      object-fit: cover;
      transition: all 0.2s ease;
    }

    .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.helper};
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
      letter-spacing: 1px;
    }
  }

  .card {
    background-color: #fff;
    border-radius: 1rem;
    display: inline-block;
    border: 2px solid #e1e1e1;
    padding: 1rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    }

    .card-data {
      padding: 1.5rem;
    }

    .card-data-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5rem;
    }

    h3 {
      color: ${({ theme }) => theme.colors.text};
      font-size: 1.8rem;
      text-transform: capitalize;
    }

    .card-data--price {
      color: ${({ theme }) => theme.colors.helper};
      font-size: 1.6rem;
    }

    .btn {
      margin-top: 1.5rem;
      background-color: ${({ theme }) => theme.colors.primary};
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2rem;
      padding: 1rem 2rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondary};
      }

      a {
        color: #fff;
        font-size: 1.4rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        transition: color 0.3s ease;
      }
    }
  }
`;

export default FeatureProduct;

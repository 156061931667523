import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const PageNavigation = ({ title }) => {
  return (
    <Wrapper>
      <NavLink to="/products" className="home-link">
        Products
      </NavLink>
      <span className="separator">/</span>
      <span className="title">{title}</span>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: auto;
  background-color: ${({ theme }) => theme.colors.bg};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  padding: 1rem 2rem;
  position: relative;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.text};

  /* Style for the links */
  .home-link,
  .title {
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .home-link:hover,
  .title:hover {
    color: ${({ theme }) => theme.colors.helper};
    text-decoration: underline;
  }

  .separator {
    margin: 0 1rem;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 500;
  }

  /* Responsive design adjustments */
  @media (max-width: 768px) {
    font-size: 1.6rem;
    .home-link,
    .separator,
    .title {
      font-size: 1.6rem;
    }
  }

  /* For larger screens */
  @media (min-width: 1024px) {
    font-size: 2.4rem;
    .home-link,
    .separator,
    .title {
      font-size: 2.4rem;
    }
  }
`;

export default PageNavigation;

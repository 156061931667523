import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../styles/Button";

const HeroSection = ({ myData }) => {
  const { paragraph } = myData;

  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column">
          <div className="hero-section-data">
            <p className="intro-data">Welcome to </p>
            <h1> Hamza's Retro Consoles</h1>
            <p>{paragraph}</p>
            <NavLink to="/products">
              <Button>Shop Now</Button>
            </NavLink>
          </div>
          {/* our homepage image  */}
          <div className="hero-section-image">
            <figure>
              <img
                src="images/ds.jpg"
                alt="hero-section-photo"
                className="img-style"
              />
            </figure>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 10rem 2rem;
  background: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.colors.primaryLight},
    ${({ theme }) => theme.colors.primaryDark}
  ); /* Vibrant gradient background */
  color: ${({ theme }) => theme.colors.lightText};

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; /* Rounded corners for images */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Add depth */
  }

  .hero-section-data {
    text-align: left;

    h1 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 4.5rem; /* Increased for emphasis */
      color: ${({ theme }) => theme.colors.lightText};
      line-height: 1.3; /* Better spacing */
    }

    p {
      margin: 2rem 0;
      font-size: 1.8rem; /* Increased font size */
      font-weight: 400;
      line-height: 2; /* Enhanced readability */
      color: ${({ theme }) => theme.colors.lightText};
    }

    .intro-data {
      font-size: 1.6rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.accent};
    }
  }

  .hero-section-image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      border: 5px solid ${({ theme }) => theme.colors.lightText};
      transition: transform 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1); /* Slight zoom effect */
      }
    }
  }

  figure {
    position: relative;

    &::after {
      content: "";
      width: 70%;
      height: 70%;
      background-color: rgba(255, 255, 255, 0.2);
      position: absolute;
      left: 15%;
      top: -10%;
      border-radius: 50%;
      z-index: -1;
    }
  }

  /* Responsive styles for mobile */
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 6rem 1.5rem;
    text-align: center;

    .hero-section-data {
      h1 {
        font-size: 3.2rem;
      }

      p {
        font-size: 1.6rem;
      }

      .intro-data {
        font-size: 1.4rem;
      }
    }

    figure::after {
      width: 50%;
      height: 50%;
      left: 25%;
      top: 10%;
    }
  }
`;

export default HeroSection;

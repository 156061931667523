import styled from "styled-components";

const Contact = () => {
  const Wrapper = styled.section`
    padding: 9rem 0 5rem 0;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.bg};

    .common-heading {
      font-size: 3rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary};
      margin-bottom: 2rem;
    }

    iframe {
      border: none;
      width: 100%;
      max-height: 400px;
      margin-bottom: 3rem;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6rem;
    }

    .contact-form {
      background-color: ${({ theme }) => theme.colors.white};
      padding: 3rem;
      border-radius: 8px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      max-width: 50rem;
      width: 100%;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.02);
      }

      .contact-inputs {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        input,
        textarea {
          width: 100%;
          padding: 1rem;
          border: 1px solid ${({ theme }) => theme.colors.border};
          border-radius: 5px;
          font-size: 1.4rem;
          color: ${({ theme }) => theme.colors.text};
          background-color: ${({ theme }) => theme.colors.inputBg};
          transition: all 0.3s ease;

          &:focus {
            border-color: ${({ theme }) => theme.colors.btn};
            outline: none;
            box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.btn};
          }
        }

        input[type="submit"] {
          cursor: pointer;
          background-color: ${({ theme }) => theme.colors.btn};
          color: ${({ theme }) => theme.colors.white};
          border: none;
          transition: all 0.2s ease;
          font-size: 1.6rem;

          &:hover {
            background-color: ${({ theme }) => theme.colors.btnHover};
            transform: scale(1.05);
          }
        }
      }
    }

    @media (max-width: 768px) {
      .common-heading {
        font-size: 2rem;
      }

      .contact-form {
        padding: 2rem;
        max-width: 100%;
      }
    }
  `;

  return (
    <Wrapper>
      <h2 className="common-heading">Contact Us</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d850.884586415365!2d74.39049!3d31.454374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1709388894252!5m2!1sen!2s"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="container">
        <div className="contact-form">
          <form
            action="https://formspree.io/f/xleqnvka"
            method="POST"
            className="contact-inputs"
          >
            <input
              type="text"
              placeholder="Name"
              name="name"
              required
              autoComplete="off"
            />
            <input
              type="tel"
              id="mobile_number"
              name="mobile_number"
              placeholder="Mobile no."
              pattern="03[0-9]{9}"
              required
            />
            <textarea
              name="message"
              placeholder="Enter your message..."
              cols="30"
              rows="10"
              required
              autoComplete="off"
            />
            <input type="submit" value="Send" />
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default Contact;

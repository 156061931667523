import React from "react";
import { useFilterContext } from "../context/FilterContext";
import { FaCheck } from "react-icons/fa";
import FormatPrice from "../Helpers/FormatPrice";
import styled from "styled-components";
import { Button } from "../styles/Button";

const FilterSection = () => {
  const {
    filters: { text, category, color, price, maxPrice, minPrice },
    updateFilterValue,
    all_products,
    clearFilters,
  } = useFilterContext();

  // get the unique values of each property
  const getUniqueData = (data, attr) => {
    let newVal = data.map((curElem) => {
      return curElem[attr];
    });

    if (attr === "color") {
      newVal = newVal.flat();
    }

    return (newVal = ["all", ...new Set(newVal)]);
  };

  const categoryData = getUniqueData(all_products, "category");
  const companyData = getUniqueData(all_products, "company");
  const colorsData = getUniqueData(all_products, "color");

  return (
    <Wrapper>
      <div className="filter-search">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="text"
            placeholder="Search"
            value={text}
            onChange={updateFilterValue}
            onBlur={updateFilterValue} // Added onBlur event here
          />
        </form>
      </div>

      {/* Category Section */}
      <div className="filter-category">
        <h3>Category</h3>
        <select
          name="category"
          value={category}
          onChange={updateFilterValue} // Changed to onChange
          className="filter-category--select"
        >
          {categoryData.map((curElem, index) => {
            return (
              <option key={index} value={curElem}>
                {curElem}
              </option>
            );
          })}
        </select>
      </div>

      {/* Company Section */}
      <div className="filter-company">
        <h3>Company</h3>
        <form action="#">
          <select
            name="company"
            id="company"
            className="filter-company--select"
            onChange={updateFilterValue} // Changed to onChange
          >
            {companyData.map((curElem, index) => {
              return (
                <option key={index} value={curElem} name="company">
                  {curElem}
                </option>
              );
            })}
          </select>
        </form>
      </div>

      {/* Colors Section */}
      <div className="filter-colors colors">
        <h3>Colors</h3>

        <div className="filter-color-style">
          {colorsData.map((curColor, index) => {
            if (curColor === "all") {
              return (
                <button
                  key={index}
                  type="button"
                  value={curColor}
                  name="color"
                  className="color-all--style"
                  onClick={updateFilterValue} // Changed to onChange
                >
                  All
                </button>
              );
            }
            return (
              <button
                key={index}
                type="button"
                value={curColor}
                name="color"
                style={{ backgroundColor: curColor }}
                className={color === curColor ? "btnStyle active" : "btnStyle"}
                onClick={updateFilterValue} // Changed to onChange
              >
                {color === curColor ? <FaCheck className="checkStyle" /> : null}
              </button>
            );
          })}
        </div>
      </div>

      {/* Price Range Section */}
      <div className="filter_price">
        <h3>Price</h3>
        <p>
          <FormatPrice price={price} />
        </p>
        <input
          type="range"
          name="price"
          min={minPrice}
          max={maxPrice}
          value={price}
          onChange={updateFilterValue} // Changed to onChange
        />
      </div>

      {/* Clear Filters Button */}
      <div className="filter-clear">
        <Button className="btn" onClick={clearFilters}>
          Clear Filters
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  h3 {
    padding: 1.5rem 0;
    font-weight: bold;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.primary};
  }

  .filter-search {
    input {
      padding: 0.6rem 1rem;
      width: 100%;
      font-size: 1rem;
    }
  }

  .filter-category,
  .filter-company {
    div,
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    button,
    select {
      border: 1px solid ${({ theme }) => theme.colors.grey};
      padding: 0.8rem 1rem;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      text-transform: capitalize;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 4px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: ${({ theme }) => theme.colors.btn};
        color: ${({ theme }) => theme.colors.white};
      }

      &.active {
        background-color: ${({ theme }) => theme.colors.btn};
        color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.btn};
      }
    }

    select {
      font-size: 1.1rem;
      padding: 0.8rem 1.2rem;
      border-radius: 4px;
    }
  }

  .filter-color-style {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  .btnStyle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .filter_price {
    input {
      width: 100%;
    }
  }

  .filter-clear .btn {
    background-color: #ec7063;
    color: #fff;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    gap: 2rem;
    h3 {
      font-size: 1.5rem;
    }

    .filter-search input {
      font-size: 0.9rem;
    }

    .btnStyle {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  @media (max-width: 480px) {
    padding: 3rem 0.5rem;
    h3 {
      font-size: 1.2rem;
    }

    .filter-search input {
      font-size: 0.8rem;
    }

    .btnStyle {
      width: 1.5rem;
      height: 1.5rem;
    }

    .filter-clear .btn {
      font-size: 0.8rem;
      padding: 0.6rem 1rem;
    }
  }
`;

export default FilterSection;
